import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Container } from "reactstrap"
// import SEO from "../components/seo"
import Head from "../components/head"

const color = {
  color: "#000",
  cursor: "pointer",
}

const spread = {
  marginBottom: "1rem",
}

const NotFoundPage = () => (
  <Layout>
    <Head
      title="404 Page"
      description="The page you were looking for does not exist."
    />

    <Container>
      {/* <SEO title="404: Not found" /> */}
      <h2>NOT FOUND</h2>
      <p>
        Sorry to throw you but this page does not exist at this time. Please
        click on the department you are looking for:
      </p>
      <div className="col-lg-12" style={spread}>
        <Link to="/promotional/index.htm">
          <div style={color}>
            <div>
              <strong>Throw Your Name Around</strong>
            </div>
            <div>Custom Printed</div>
            <div>Promotional Products</div>
          </div>
        </Link>
      </div>
      <div className="col-lg-12" style={spread}>
        <Link to="/throw-your-voice" style={color}>
          <div>
            <strong>Throw Your Voice</strong>
          </div>
          <div>Ventriloquist Dummies, Puppets</div>
          <div> & Marionettes</div>
        </Link>
      </div>
      <div className="col-lg-12" style={spread}>
        <Link to="/tt-things-to-throw" style={color}>
          <div>
            <strong>Things To Throw</strong>
          </div>
          <div>Sports, Games, Toys & More</div>
        </Link>
      </div>
      <div className="col-lg-12" style={spread}>
        <Link to="/throwbacks" style={color}>
          <div>
            <strong>Throwbacks</strong>
          </div>
          <div>Classic Toys, Games & Gags</div>
        </Link>
      </div>
      <div className="col-lg-12" style={spread}>
        <Link to="/throw-up" style={color}>
          <div>
            <strong>Throw Up</strong>
          </div>
          <div>Fake Vomit & Other Gross Stuff</div>
        </Link>
      </div>
      <div className="col-lg-12" style={spread}>
        <Link to="/throw-a-party" style={color}>
          <div>
            <strong>Throw A Party</strong>
          </div>
          <div>Party Supplies & Costumes</div>
        </Link>
      </div>
      <div className="col-lg-12" style={spread}>
        <Link to="/throw-your-money-away" style={color}>
          <div>
            <strong>Throw Your Money Away</strong>
          </div>
          <div>We Make It Easy</div>
        </Link>
      </div>
      <div className="col-lg-12" style={spread}>
        <Link to="/throwthings-things" style={color}>
          <div>
            <strong>ThrowThings Things</strong>
          </div>
          <div>You ThrowThings - Tell The World!</div>
        </Link>
      </div>
      <div className="col-lg-12" style={spread}>
        <Link to="/throw-it-on-the-barbie" style={color}>
          <div>
            <strong>Throw It On The Barbie</strong>
          </div>
          <div>Portable Grills & More</div>
        </Link>
      </div>
      <div className="col-lg-12">
        <Link to="/throw-things-away" style={color}>
          <div>
            <strong>Throw Things Away</strong>
          </div>
          <div>Coming Soon!</div>
        </Link>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
